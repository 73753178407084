<template>
  <loading :active.sync="isLoading"
           :can-cancel="false"
           :is-full-page="fullPage">
      uploading...
  </loading>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'LoadingComponent',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: true,
      fullPage: false
    }
  },
  methods: {
    // doAjax() {
    //   this.isLoading = true;
    //   // simulate AJAX
    //   setTimeout(() => {
    //     this.isLoading = false
    //   },5000)
    // },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>
