import axios from 'axios'
// import { Message } from 'element-ui'
import store from '../store/store'
import {getToken, removeToken} from "@/utils/auth";
// import VueScrollTo from 'vue-scrollto'
// import i18n from '@/lang'
import Vue from 'vue';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL+ '/api/v1/admin/', // api base_url
  timeout: 120000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    // if (store.getters.token) {
      // config.headers['X-Token'] = getToken()
      config.headers['Authorization'] = 'Bearer ' + getToken()
    // }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
  	if (response.data.message) {
      // Message({
      //   message: response.data.message,
      //   type: 'success',
      //   duration: 5 * 1000
      // })
    }
  	return response
  },

  error => {
    // console.log('err' + error) // for debug
    let mess = ''
    if (error.response.status === 500) {
    	mess = 'The system is maintenance'
    }
    if (error.response.status === 401 || error.response.status === 403) {
        mess = 'Unauthorized error'
        setTimeout(() => {
            removeToken();
            window.location.reload();
            // VueScrollTo.scrollTo('.el-form-item.is-error', 500)
        }, 2000)
    }
    if (error.response.status === 408 && error.response.data?.message) {
      mess = error.response.data?.message
    }
    if (error.response.status === 429) {
      mess = 'Too Many Requests'
    }
    if (mess) {
        Vue.$toast.error(mess, {duration: 3000})
    }
    if (error.response.status === 400 && error.response.data?.message) {
      // Message({
      //   message: error.response.data?.message,
      //   type: 'error',
      //   duration: 3 * 1000
      // })
    }
    if (error.response.status === 422) {
      setTimeout(() => {
        // VueScrollTo.scrollTo('.el-form-item.is-error', 500)
      })
    }
    return Promise.reject(error)
  }
)

export default service
