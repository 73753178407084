import Vue from 'vue'
import Vuex from 'vuex'
import userApi from "@/api/userApi";
import getters from './getters'
// import app from './modules/app'
// import settings from './modules/settings'
import user from './modules/user'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  token: '',
  userInfo: '',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  getUserInfo(state ) {
    return new Promise((resolve, reject) => {
      userApi.profile().then(response => {
        if (!response.data) {
          reject('error')
        }
        state.userInfo = response.data.user
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default new Vuex.Store({
    modules: {
      user
    },
    getters,
    state,
    mutations
})
