import Vue from 'vue'
import Router from 'vue-router'
import { SCREEN_KEY } from "@/utils/constance";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/auth/Login')
const Register = () => import('@/views/pages/Register')

// Users
const User = () => import('@/views/users/User')

// Stocks
const Stocks = () => import('@/views/stocks/Stocks')
const Users = () => import('@/views/users/Users')
const StocksImport = () => import('@/views/stocks/StocksImport')
const ScanQR = () => import('@/views/stocks/ScanQR')
const CreateStock = () => import('@/views/stocks/CreateStock')
const StockDetail = () => import('@/views/stocks/StockDetail')
const ImportSchedule = () => import('@/views/stocks/ImportSchedule')
const CreateSchedule = () => import('@/views/stocks/CreateSchedule')
const ScheduleDetail = () => import('@/views/stocks/ScheduleDetail')

// ItemMasters
const ItemMastersList = () => import('@/views/item-masters/ItemMastersList')
const CreateItemMaster = () => import('@/views/item-masters/CreateItemMaster')
const ImportItemMaster = () => import('@/views/item-masters/ImportItemMaster')

// Sales Histories
const SalesHistoriesList = () => import('@/views/sales-histories/List')
const ScanQRSales = () => import('@/views/sales-histories/ScanQR')

// Sizes
const Sizes = () => import('@/views/sizes/Sizes')
const Size = () => import('@/views/sizes/Size')

// Colors
const Colors = () => import('@/views/colors/Colors')
const Color = () => import('@/views/colors/Color')

// Seasons
const Seasons = () => import('@/views/seasons/Seasons')
const Season = () => import('@/views/seasons/Season')

// Shelves
const Shelves = () => import('@/views/shelves/Shelves')
const Shelf = () => import('@/views/shelves/Shelf')

// MajorCategories
const MajorCategories = () => import('@/views/major-categories/MajorCategories')
const MajorCategory = () => import('@/views/major-categories/MajorCategory')

// MiddleCategories
const MiddleCategories = () => import('@/views/middle-categories/MiddleCategories')
const MiddleCategory = () => import('@/views/middle-categories/MiddleCategory')

// MinorCategories
const MinorCategories = () => import('@/views/minor-categories/MinorCategories')
const MinorCategory = () => import('@/views/minor-categories/MinorCategory')

// Cardboard
const Cardboards = () => import('@/views/cardboards/CardBoards')
const CreateCardboard = () => import('@/views/cardboards/CreateCardboard')
const CardboardQrScan = () => import('@/views/inspects/ScanQR')
const SelectItemCardboard = () => import('@/views/cardboards/SelectItemCardboard')
const InspectCardboardQrScan = () => import('@/views/inspects/ItemScanQR')

// shop
const Shops = () => import('@/views/shops/Shops')
const Shop = () => import('@/views/shops/Shop')

// Inventory Work
const InventoryWorksList = () => import('@/views/inventory-works/InventoryWorksList')
const InventoryWorkForm = () => import('@/views/inventory-works/InventoryWorkForm')
const SelectItemQR = () => import('@/views/inventory-works/SelectItemQR')
const InventoryWorkDetail = () => import('@/views/inventory-works/InventoryWorkDetail')
const InventoryWorkSummary = () => import('@/views/inventory-works/InventoryWorkSummary')

Vue.use(Router)

export default new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            children: [
                {
                    path: 'dashboard',
                    name: 'ダッシュボード',
                    component: Dashboard
                },
                {
                    path: 'stocks',
                    // meta: {
                    name: '在庫',
                    redirect: '/inventory',
                    // },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: Stocks,
                            props: {
                                screen_key: SCREEN_KEY.LIST_STOCKS
                            },
                        },
                        {
                            path: 'import',
                            name: 'CSVアップロード',
                            component: StocksImport
                        },
                        {
                            path: 'create',
                            name: 'Create Stock',
                            meta: {
                                label: '入荷登録'
                            },
                            component: CreateStock
                        },
                        {
                            path: 'schedule',
                            name: 'CSVアップロード予約一覧',
                            redirect: 'schedules',
                            component: {
                                render(c) {
                                    return c('router-view')
                                }
                            },
                            children: [
                                {
                                    path: '',
                                    name: '',
                                    component: ImportSchedule
                                },
                                {
                                    path: 'create',
                                    name: '新規予約',
                                    component: CreateSchedule
                                },
                                {
                                    path: ':id',
                                    name: '詳細',
                                    component: ScheduleDetail
                                },
                            ]
                        },
                        {
                            path: 'schedules',
                            name: 'CSVアップロード予約一覧',
                            component: {
                                render(c) {
                                    return c('router-view')
                                }
                            },
                            children: [
                                {
                                    path: '',
                                    name: '',
                                    component: ImportSchedule
                                },
                            ]
                        },
                        {
                            path: ':id',
                            name: 'Stock Details',
                            meta: {
                                label: "在庫詳細"
                            },
                            component: StockDetail
                        },
                        // {
                        //     path: 'scan-qr',
                        //     meta: {
                        //         label: '検品'
                        //     },
                        //     name: '検品',
                        //     component: ScanQR
                        // }
                    ]
                },
                {
                    path: 'inventory',
                    name: '在庫',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            props: {
                                screen_key: SCREEN_KEY.LIST_INVENTORY
                            },
                            component: Stocks
                        },
                    ]
                },
                {
                    path: 'shipping',
                    name: '出荷',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            props: {
                                screen_key: SCREEN_KEY.LIST_SHIPPING
                            },
                            component: Stocks
                        },
                    ]
                },
                // {
                //     path: 'return',
                //     name: '返品',
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: '',
                //             name: '',
                //             props: {
                //                 screen_key: SCREEN_KEY.LIST_RETURN
                //             },
                //             component: Stocks
                //         },
                //     ]
                // },
                {
                    path: 'item-masters',
                    name: '商品マスタ',
                    redirect: '/item-masters',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            props: {
                                screen_key: SCREEN_KEY.LIST_ITEM_MASTERS
                            },
                            component: ItemMastersList,
                        },
                        {
                            path: 'create',
                            name: 'Create Item Master',
                            meta: {
                                label: '商品マスタ'
                            },
                            component: CreateItemMaster,
                        },
                        {
                            path: 'import',
                            name: 'Import Item Master',
                            meta: {
                                label: 'CSVアップロード'
                            },
                            component: ImportItemMaster,
                        },
                        {
                            path: ':id',
                            meta: {
                                label: '商品更新'
                            },
                            props: {
                                isUpdate: true
                            },
                            name: 'Update Item Master',
                            component: CreateItemMaster
                        }
                    ]
                },
                {
                    path: 'users',
                    name: 'ユーザーマスタ',
                    redirect: '/users',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            props: {
                                screen_key: SCREEN_KEY.LIST_USERS
                            },
                            component: Users
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'ユーザーを追加'
                            },
                            name: 'User',
                            component: User
                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'ユーザー更新'
                            },
                            name: 'User',
                            component: User
                        }
                    ]
                },
                {
                    path: 'sales-history',
                    name: '販売履歴',
                    redirect: '/sales-history',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: SalesHistoriesList
                        },
                        {
                            path: 'scan-qr',
                            meta: {
                                label: '販売登録'
                            },
                            name: '販売登録',
                            component: ScanQRSales
                        }
                    ]
                },
                {
                    path: 'sizes',
                    name: 'サイズ',
                    redirect: '/sizes',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: Sizes
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'サイズを追加'
                            },
                            name: 'Size',
                            component: Size

                        },
                        {
                            path: ':id',
                            meta: {
                                label: '更新する'
                            },
                            name: 'Size',
                            component: Size
                        }
                    ]
                },
                {
                    path: 'colors',
                    name: 'カラー',
                    redirect: '/colors',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: Colors
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'カラーを追加'
                            },
                            name: 'Color',
                            component: Color

                        },
                        {
                            path: ':id',
                            meta: {
                                label: '色を編集する'
                            },
                            name: 'Color',
                            component: Color
                        }
                    ]
                },
                {
                    path: 'seasons',
                    name: 'シーズン',
                    redirect: '/seasons',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: Seasons
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'シーズンを追加'
                            },
                            name: 'Season',
                            component: Season

                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'シーズンの編集'
                            },
                            name: 'Season',
                            component: Season
                        }
                    ]
                },
                {
                    path: 'shelves',
                    name: '棚',
                    redirect: '/shelves',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: Shelves
                        },
                        {
                            path: 'create',
                            meta: {
                                label: '棚を追加'
                            },
                            name: 'Shelf',
                            component: Shelf

                        },
                        {
                            path: ':id',
                            meta: {
                                label: '棚を編集する'
                            },
                            name: 'Shelf',
                            component: Shelf
                        }
                    ]
                },
                {
                    path: 'major-categories',
                    name: '大分類',
                    redirect: '/major-categories',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: MajorCategories
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'メジャーカテゴリを追加'
                            },
                            name: 'MajorCategory',
                            component: MajorCategory

                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'メジャーカテゴリの編集'
                            },
                            name: 'MajorCategory',
                            component: MajorCategory
                        }
                    ]
                },
                {
                    path: 'middle-categories',
                    name: '中分類',
                    redirect: '/middle-categories',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: MiddleCategories
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'ミドルカテゴリを追加'
                            },
                            name: 'MiddleCategory',
                            component: MiddleCategory

                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'ミドルカテゴリーの編集'
                            },
                            name: 'MiddleCategory',
                            component: MiddleCategory
                        }
                    ]
                },
                {
                    path: 'minor-categories',
                    name: '小分類',
                    redirect: '/minor-categories',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: MinorCategories
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'マイナーカテゴリを追加'
                            },
                            name: 'MinorCategory',
                            component: MinorCategory
                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'マイナーカテゴリの編集'
                            },
                            name: 'MinorCategory',
                            component: MinorCategory
                        }
                    ]
                },
                {
                    path: 'cardboard',
                    name: '出荷・返品段ボール一覧',
                    redirect: '/cardboard',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            props: {
                                screen_key: SCREEN_KEY.LIST_CARDBOARD
                            },
                            component: Cardboards
                        },
                        {
                            path:'create',
                            name: '出荷段ボール登録',
                            meta: {
                                label: '追加'
                            },
                            component: CreateCardboard,
                        },
                        {
                            path: ':id',
                            meta: {
                                label: '登録'
                            },
                            name: 'Cardboards Edit',
                            component: SelectItemCardboard
                        }
                    ],
                },
                {
                    path: 'inspect',
                    name: '入荷予定在庫一覧',
                    redirect: '/inspect',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            props: {
                                screen_key: SCREEN_KEY.LIST_CARDBOARD_INSPECT
                            },
                            component: Cardboards
                        },
                        // {
                        //     path: 'qr-scan',
                        //     name: '',
                        //     meta: {
                        //         label: '検品'
                        //     },
                        //     component: CardboardQrScan
                        // },
                        {
                            path: ':id',
                            meta: {
                                label: 'Inspect Cardboard'
                            },
                            name: 'Inspect Cardboard',
                            component: InspectCardboardQrScan
                        }
                    ]
                },
                {
                    path: 'shops',
                    name: 'ショップ',
                    redirect: '/shops',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: Shops
                        },
                        {
                            path: 'create',
                            meta: {
                                label: 'ショップを作成する'
                            },
                            name: 'Shop',
                            component: Shop
                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'ショップを編集'
                            },
                            name: 'Shop',
                            component: Shop
                        }
                    ]
                },
                {
                    path: 'inventory-works',
                    name: '在庫棚卸し',
                    redirect: '/inventory-works',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: '',
                            component: InventoryWorksList
                        },
                        {
                            path: 'create',
                            meta: {
                                label: '新規'
                            },
                            name: 'Inventory Work Create',
                            component: InventoryWorkForm
                        },
                        {
                            path: ':id',
                            meta: {
                                label: '詳細'
                            },
                            name: 'Inventory Work Detail',
                            component: InventoryWorkDetail
                        },
                        {
                            path: ':id/summary',
                            meta: {
                                label: 'サマリー'
                            },
                            name: 'Inventory Work Summary',
                            component: InventoryWorkSummary
                        },
                        {
                            path: ':id/edit',
                            meta: {
                                label: '編集'
                            },
                            name: 'Inventory Work Edit',
                            component: SelectItemQR
                        }
                    ]
                },
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                }
            ]
        }
    ]
}
