import userApi from "@/api/userApi";
import { getToken, setToken, removeToken } from '@/utils/auth'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        user: ''
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_USER: (state, user) => {
        state.user = user
    }
}

const actions = {
    login({ commit }, userInfo) {

        const { email, password } = userInfo
        return new Promise((resolve, reject) => {
            userApi.login( email.trim(), password ).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                commit('SET_NAME', data.user?.name)
                commit('SET_USER', data.user)

                setToken(data.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            userApi.profile().then(response => {
                const { data } = response
                if (!data) {
                    return reject('Verification failed, please Login again.')
                }

                commit('SET_USER', data.user)
                const { name } = data.user
                commit('SET_NAME', name)

                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // // // user logout
    // logout({ commit, state }) {
    //     return new Promise((resolve, reject) => {
    //         logout(state.token).then(() => {
    //             removeToken() // must remove  token  first
    //             resetRouter()
    //             commit('RESET_STATE')
    //             resolve()
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
