import request from './request'

const userApi = {
  profile(query) {
    return request({
      url: 'me',
      method: 'get',
      params: query
    })
  },
  login(email, password) {
      const data = {
          email,
          password
      }
      return request({
          url: '/login',
          method: 'post',
          data
      })
  },
  logout() {
      return request({
          url: '/logout',
          method: 'post'
      })
  },

  editProfile(query) {
    return request({
      url: 'users/edit-profile',
      method: 'post',
      params: query
    })
  },
  changePassword(query) {
    return request({
      url: 'users/change-password',
      method: 'post',
      params: query
    })
  },
  edit(query) {
    return request({
      url: 'users' + '/' + query.id,
      method: 'patch',
      data: query
    })
  },
  roles(query) {
    return request({
      url: 'roles',
      method: 'get',
      params: query
    })
  },
  activeUser(query) {
    return request({
      url: 'users/active-user',
      method: 'post',
      params: query
    })
  },
  activeLoginUser(query) {
    return request({
      url: 'users/active-login-user',
      method: 'post',
      params: query
    })
  },
  getUserShop() {
    return request({
      url: 'users/get-user-shop',
      method: 'get'
    })
  }
}
export default userApi
