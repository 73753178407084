import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import './permission'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/store'
import Loading from "@/components/Loading";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import UploadLoading from "@/components/UploadLoading";
import {
    cilPencil, cilDelete, cilRecycle,
    cilDrop, cilSpeedometer, cilUser,
    cilShare, cibShopify, cilColorPalette,
    cilResizeBoth, cilSun, cilStorage,
    cilFindInPage, cilHouse,cilTrash,
    cilMagnifyingGlass
    } from '@coreui/icons'

Vue.use(VueToast ,{
    position: 'top',
    duration: 3000
});

Vue.component('LoadingCompoment', Loading)
Vue.component('UploadLoadingCompoment', UploadLoading)
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons:  {
      cilPencil, cilDelete, cilRecycle,
      cilDrop, cilSpeedometer, cilUser,
      cilShare, cibShopify, cilColorPalette,
      cilResizeBoth, cilSun, cilStorage,
      cilFindInPage, cilHouse,cilTrash,
      cilMagnifyingGlass
  },
  template: '<App/>',
  components: {
    App
  }
})
