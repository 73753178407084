import router from './router'
import store from './store/store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css'// progress bar style
import { getToken } from './utils/auth'
// import i18n from '@/lang'

// NProgress.configure({ showSpinner: false })// NProgress Configuration

// permission judge function
function hasPermission(roles, permissionRoles) {
  return true
  // eslint-disable-next-line no-unreachable
  if (roles.indexOf('admin') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

const whiteList = ['/pages/login', '/auth-redirect', '/forgot', '/reset', '/active-user']// no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start() // start progress bar
  let token = getToken();
  if (token) { // determine if there has token
    /* has token*/
    if (to.path === '/pages/login') {
      next({ path: '/dashboard' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
        const hasGetUserInfo = store.getters.name

        if (hasGetUserInfo) {
            next()
        } else {
            try {
                // get user info
                await store.dispatch('user/getInfo')
                next()
            } catch (error) {
                // remove token and go to login page to re-login
                // await store.dispatch('user/resetToken')
                // Message.error(error || 'Has Error')
                next(`/pages/login?redirect=${to.path}`)
                NProgress.done()
            }
        }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      next()
        NProgress.done()
    } else {
      next(`/pages/login?redirect=${to.path}`)
        NProgress.done()
      // NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
  // store.dispatch('getSetting').then(r => {})
  // NProgress.done()
})

router.afterEach(() => {
  // NProgress.done() // finish progress bar
})
