export const STOCK_STATUS = {
    1: '入荷',
    2: '出荷',
    3: '返品',
    4: '入荷[済]',
    5: '出荷[済]',
    6: '返品[済]',
    7: '販売済',
}

export const SCREEN_KEY = {
    LIST_STOCKS: 'LIST_STOCKS',
    LIST_INVENTORY: 'LIST_INVENTORY',
    LIST_SHIPPING: 'LIST_SHIPPING',
    LIST_RETURN: 'LIST_RETURN',
    ADD_INVENTORY: 'ADD_INVENTORY',
    SCAN_QR_CODE: 'SCAN_QR_CODE',
    ITEM_SCAN_QR_CODE: 'ITEM_SCAN_QR_CODE',
    LIST_ITEM_MASTERS: 'LIST_ITEM_MASTERS',
    LIST_USERS: 'LIST_USERS',
    LIST_CARDBOARD: 'LIST_CARDBOARD',
    LIST_CARDBOARD_INSPECT: 'LIST_CARDBOARD_INSPECT',
    ITEM_SCAN_QR_CODE_FOR_SOLD: 'ITEM_SCAN_QR_CODE_FOR_SOLD'
}

export const ALL_SHOPS = -1;
export const WAREHOUSUE = 1;

export const STATUS_CARDBOARD_ITEM = {
    UN_INSPECT: 0,
    INSPECT: 1,
}
export const STATUS_CARDBOARD_ITEM_NAME = {
    0: '未検品',
    1: '検品済'
}

export const STATUS_CARDBOARD = {
    UN_SHIP: 1,
    SHIPPED: 2,
    RECIEVED: 3,
    RETURN: 4
}

export const NORMAL_STOCK = '1';

export const STATUS_ITEM = {
    IN_STOCK: 1,
    IN_BOX: 2,
    SOLD: 3,
    DEAD_STOCK: 4,
}

export const STATUS_INVENTORY_ITEM = {
    1: '在庫あり',
    2: '棚卸未対応',
    3: '在庫なし',
    4: '販売済み'
}

export const STATUS_INVENTORY_WORK = {
    0: '未処理',
    1: '棚卸し済み'
}


